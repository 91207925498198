import { Typography } from 'antd';
import styled from 'styled-components';

import { AccountConnectTypes } from 'shared/components/ConnectAssets/components/AccountCards/consts';
import Safe from 'shared/images/icons/safe.svg';

const { Text } = Typography;

const Title = styled(Text)`
  color: #0d0d0d;
  font-weight: 500;
`;

export const InfoBlockWrapper = styled.div`
  display: flex;
  background: #f3f3f3;
  padding: 16px;
  border-radius: 12px;
`;

const Info = styled(Text)`
  font-size: 13px;
  line-height: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

interface Props {
  accountType: AccountConnectTypes;
}

const InfoFieldsMap = {
  [AccountConnectTypes.Wallet]: {
    title: 'Read-only permission',
    info: 'We never ask users Private key. This does not give us abality to move your funds.',
  },
  [AccountConnectTypes.Exchange]: {
    title: 'Read-only permission',
    info: 'We required API keys only with read permission.',
  },
  [AccountConnectTypes.Fierblocks]: {
    title: 'Read-only permission',
    info: 'We required API keys only with read permission.',
  },
  [AccountConnectTypes.LooseWallet]: {
    title: 'Loose Wallet',
    info: 'Loose Wallet.',
  },
};
export const InfoBlock = ({ accountType }: Props) => {
  const { info, title } = InfoFieldsMap[accountType];

  return (
    <InfoBlockWrapper>
      <Title>
        <Safe />
      </Title>
      <Content>
        <div>
          <Title>{title}</Title>
        </div>
        <Info type="secondary">{info}</Info>
      </Content>
    </InfoBlockWrapper>
  );
};

export interface Exchange {
  exchange_code: string;
  exchange_name: string;
  related_types: string[];
  trusted_ips: string[];
  trusted_ips_input_type: string;
  connection_allowed: boolean;
  fast_connect_supported: boolean;
  connect_via_api_keys_allowed: boolean;
}

export interface ExchangesField {
  name: string;
  label: string;
  rules?: Record<string, any>[];
  type?: string;
}

// [BITHUMB,BITSO,CIRCLE, COINBASEPRO, COINMETRO, COINSPRO, FIXUS, HITBTC, KORBIT, LIQUID, POLONIEX, OKCOIN, SEEDCX ]

export const FIERBLOCKS_EXCHANGE_MAP = {
  BINANCE: 'binance',
  BINANCEUS: 'binance_us',
  BITFINEX: 'bitfinex',
  BITHUMB: 'BITHUMB',
  BITMEX: 'bitmex',
  BITSTAMP: 'bitstamp',
  BITTREX: 'bittrex',
  CRYPTOCOM: 'crypto_com',
  DERIBIT: 'deribit',
  GEMINI: 'gemini',
  HUOBI: 'huobi',
  KRAKEN: 'kraken',
  OKEX: 'okex',
};

export enum ExchangesTypes {
  Fierblocks = 'fierblocks',
  Binance = 'binance',
  BinanceSpot = 'binance_spot',
  BinanceMargin = 'binance_margin',
  BinanceFutures = 'binance_futures',
  BinanceFuturesCoin = 'binance_futures_coin',
  BinanceFunding = 'binance_funding',
  BinanceEarn = 'binance_earn',
  Bybit = 'bybit',
  BybitSpot = 'bybit_spot',
  BybitUnified = 'bybit_unified',
  BybitFund = 'bybit_fund',
  BybitOption = 'bybit_option',
  BybitContract = 'bybit_contract',
  BybitInvestment = 'bybit_investment',
  BybitUsdtPerpetual = 'bybit_usdt_perpetual',
  Bittrex = 'bittrex',
  Bitfinex = 'bitfinex',
  BitfinexExchange = 'bitfinex_exchange',
  BitfinexMargin = 'bitfinex_margin',
  BitfinexFunding = 'bitfinex_funding',
  OKX = 'okex',
  OkexFunding = 'okex_funding',
  OkexFutures = 'okex_futures',
  OkexTrading = 'okex_trading',
  KuCoin = 'kucoin',
  KucoinTrade = 'kucoin_trade',
  KucoinIsolated = 'kucoin_isolated',
  KucoinMain = 'kucoin_main',
  GateIo = 'gate_io',
  Huobi = 'huobi',
  Kraken = 'kraken',
  Gemini = 'gemini',
  Deribit = 'deribit',
  BitMEX = 'bitmex',
  Bitstamp = 'bitstamp',
  BinanceUS = 'binance_us',
  BinanceTR = 'binance_tr',
  CryptoCom = 'crypto_com',
  Solana = 'solana',
  Wallet = 'wallet',
  BtcWallet = 'btc_wallet',
  TronWallet = 'tron_wallet',
  LooseWallet = 'loose_wallet',
  Gdax = 'gdax',
  Bank = 'bank',
}

import { useEffect, useRef, useState } from 'react';
import { FixedSizeGrid } from 'react-window';
import ResizeObserver from 'resize-observer-polyfill';
import styled, { keyframes } from 'styled-components';

import { NftCard } from 'shared/components/Nfts/components/NftCard';
import IconLoading from 'shared/images/iconLoading.svg';
import NoNfts from 'shared/images/noActiveDeals.svg';

interface Nft {
  chain_name: string;
  chain_id: number;
  contract_address: string;
  token_id: string;
  amount: string;
  contract_type: string;
}

interface Props {
  list: Nft[];
  loading: boolean;
  address: string;
}

const GridWrapper = styled.div`
  .test {
    width: max-content !important;
    margin: auto;
  }
`;

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledLoading = styled(IconLoading)`
  margin: auto;
  display: flex;
  animation: ${rotateAnimation} 5s infinite linear;
`;

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 48px 0;
  text-align: center;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 24px;
  margin: 24px 0 8px 0;
`;

const Description = styled.span`
  color: #606060;
  font-size: 17px;
`;
export const Nfts = ({ list: nfts = [], loading, address }: Props) => {
  const gridRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);
  const [columnCount, setColumnCount] = useState(4);
  const list = nfts;

  useEffect(() => {
    if (!loading) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        setParentWidth(entries[0].contentRect.width);
      });

      if (gridRef.current) {
        resizeObserver.observe(gridRef.current);
      }

      return () => {
        if (gridRef.current) {
          resizeObserver.unobserve(gridRef.current);
        }
      };
    }
  }, [loading, list]);

  useEffect(() => {
    const cardWidthWithGap = 271 + 16; // ширина карточки + gap
    setColumnCount(Math.floor(parentWidth / cardWidthWithGap));
  }, [parentWidth, list]);

  if (loading) {
    return <StyledLoading />;
  }

  if (!loading && !list?.length) {
    return (
      <>
        <EmptyState>
          <NoNfts />
          <Title>You don’t have any NFTs</Title>
          <Description>Top up your wallet and return to Trackstats</Description>
        </EmptyState>
      </>
    );
  }

  const renderGridItem = ({ columnIndex, rowIndex, style, isScrolling }) => {
    const index = rowIndex * columnCount + columnIndex;
    const item = list[index];

    if (!item) {
      return null;
    }

    const isLastRow = Math.floor(list.length / columnCount) === rowIndex;
    const itemsInLastRow = list.length % columnCount;
    const modifiedStyle = {
      ...style,
      top: style.top + 99,
      marginRight: isLastRow && itemsInLastRow === 1 ? 0 : style.marginRight,
    };

    return (
      <div style={modifiedStyle}>
        <NftCard
          address={address}
          isScrolling={isScrolling}
          key={item.contract_address + item.token_id + item.contract_type + item.chain_name}
          tokenId={item.token_id}
          chainName={item.chain_name}
          contractAddress={item.contract_address}
          floorPrice={item.floorPrice}
        />
      </div>
    );
  };

  return (
    <>
      <GridWrapper ref={gridRef}>
        <FixedSizeGrid
          useIsScrolling={true}
          className="test"
          columnCount={columnCount}
          columnWidth={271 + 16}
          height={271 * 3} // Установите нужную высоту
          rowCount={Math.ceil(list.length / columnCount)}
          rowHeight={371 + 16}
          width={parentWidth}
        >
          {renderGridItem}
        </FixedSizeGrid>
      </GridWrapper>
    </>
  );
};

import classNames from 'classnames';
import styled from 'styled-components';

import type { ReactNode } from 'react';
import type { Accounts } from 'shared/components/ConnectAssets/components/AccountCards/consts';

export const StyledAccountCard = styled.div<{ background: string }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  padding: 16px;
  background: ${({ background }) => background};
  cursor: pointer;
  user-input: none;
  height: 116px;

  :hover {
    opacity: 0.7;
  }
`;

const StyledAccountName = styled.div`
  font-size: 13px;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
`;

interface Props {
  icon: ReactNode;
  name: string;
  accountKey: Accounts;
  type: string;
  background: string;
  onClick: (account: Accounts) => void;
  className: string;
}

export const AccountCard = ({ icon, name, background, onClick, accountKey, className }: Props) => {
  const handleClick = () => {
    onClick(accountKey);
  };

  return (
    <StyledAccountCard
      onClick={handleClick}
      background={background}
      className={classNames('item', 'account-card', className)}
    >
      <IconWrapper>{icon}</IconWrapper>
      <StyledAccountName>{name}</StyledAccountName>
    </StyledAccountCard>
  );
};

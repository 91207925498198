import { Table } from 'antd';
import styled from 'styled-components';

import { BIcon } from 'shared/components/Protocols/BIcon';
import EtherScanIcon from 'shared/images/etherscan.svg';

import { columns } from './Protocols.columns';

import type { AccountProtocolAsset } from './Protocols.interfaces';

interface Props {
  protocols: AccountProtocolAsset[];
}

const TablePool = styled(Table)`
  .ant-table {
    margin-block: 0 !important;
    margin: 0 !important;
  }
`;
export const ProtocolsTable = ({ protocols }: Props) => {
  return (
    <Table
      scroll={{ x: 'scrollable' }}
      tableLayout="auto"
      rowKey="id"
      size="middle"
      columns={columns}
      pagination={false}
      dataSource={protocols || []}
      expandable={{
        expandedRowRender: record => (
          <TablePool
            dataSource={record.pools}
            columns={[
              {
                title: 'Deployment Exposure',
                dataIndex: 'node_deposit',
                key: 'node_deposit',
                render: (_, { node_deposit, node_deposit_usd }) => {
                  return (
                    <div>
                      <span>{node_deposit} ETH </span>
                      <span> (${node_deposit_usd})</span>
                    </div>
                  );
                },
              },
              {
                title: 'Deployment Rewards',
                dataIndex: 'final_rewards',
                key: 'final_rewards',
                render: (_, { final_rewards, final_rewards_usd }) => {
                  return (
                    <div>
                      <span>{(+final_rewards)?.toFixed(2)} ETH </span>
                      <span> $({(+final_rewards_usd)?.toFixed(2)})</span>
                    </div>
                  );
                },
              },
              {
                title: 'Equity',
                dataIndex: 'final_rewards_usd',
                key: 'final_rewards_usd',
                render: (_, { final_rewards_usd, node_deposit_usd }) => (
                  <span>${(final_rewards_usd + node_deposit_usd)?.toFixed(2)}</span>
                ),
              },
              {
                title: 'Info',
                dataIndex: 'pub_key',
                key: 'pub_key',
                render: (_, { pub_key, address }) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                    }}
                  >
                    <a
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      target="_blank"
                      href={`https://beaconcha.in/validator/${pub_key}#rocketpool`}
                    >
                      <BIcon />
                    </a>
                    <a target="_blank" href={`https://etherscan.io/address/${address}`}>
                      <EtherScanIcon />
                    </a>
                  </div>
                ),
              },
            ]}
          />
        ),

        rowExpandable: record => !!record.pools?.length,
      }}
    />
  );
};

import styled from 'styled-components';

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
`;

export const TabItem = styled.span<{ active?: boolean }>`
  font-family: 'Cabin', sans-serif;
  font-size: 17px;
  letter-spacing: -0.4px;
  font-weight: ${props => (props.active ? 510 : 300)};
  line-height: 22px;
  padding: 24px 0 6px 0;
  cursor: pointer;
  border-bottom: ${props => (props.active ? '2px solid #213DF0' : 'none')};
  margin-left: 32px;

  &:first-child {
    margin-left: 24px;
  }
`;

import { AreaChartOutlined, LoadingOutlined } from '@ant-design/icons';
import { BarController, BarElement, CategoryScale, Chart, LinearScale } from 'chart.js';
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';

import { ChartDays } from 'shared/pages/token/components/ChartDays';
import { convertChartToCSV, downloadCSV } from 'shared/utils/toCsv';

Chart.register(BarController, BarElement, CategoryScale, LinearScale);

const ChartWrapper = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  //justify-content: end;
  flex-direction: column;
  width: 100%;

  .chartButtons {
    margin-left: auto;
    opacity: 1 !important;

    .chartButton {
      cursor: pointer;
      background: transparent;
      font-size: 12px;
      line-height: 16px;
      color: rgb(155, 166, 178);
      padding: 6px 8px;
      border: 1px solid transparent;
      border-radius: 6px;

      &.chartButtonActive {
        color: rgb(101, 119, 149);
        border: 1px solid rgb(237, 239, 241);
      }
    }
  }

  ul {
    padding: 0;
    list-style: none;
  }

  @media (max-width: 700px) {
    padding: 0;
  }
`;

const ChartActionsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  cursor: pointer;
`;

const ButtonCSV = styled.span`
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const ChartComponent = ({ data, isLoading }) => {
  const [days, setDays] = useState(30);
  const filteredData =
    data.length >= 2 && data.length >= days ? data.slice(days === 1 ? -2 : -days) : data;
  const labels = filteredData.map(item => {
    if (item.date === 'live_point') {
      return 'Live point';
    }
    const date = new Date(parseInt(item.date));
    // Получаем день, месяц и год в нужном формате
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
    const year = date.getFullYear();
    // Соединяем части даты с разделителем точка
    return `${day}.${month}.${year}`;
  });

  const pl = filteredData.map((item, index, array) => {
    const prevEl = array[index - 1]?.summary || 0;
    const currentEl = item.summary;
    return index > 0 ? currentEl - prevEl : 0;
  });

  const summaryData = filteredData.map(item => item.summary);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Summary',
        data: summaryData,
        fill: true,
        backgroundColor: 'rgba(45, 72, 241, 0.2)',
        borderColor: 'rgba(33, 61, 240, 1)',
        borderWidth: 1,
        pointHoverRadius: 10,
        pointRadius: 3,
        yAxisID: 'y-axis-1', // Указываем ID оси Y для этого набора данных
      },
      {
        type: 'bar', // Указываем, что этот набор данных будет представлен в виде столбцов
        label: 'P&L',
        data: pl,
        barThickness: filteredData.length < 30 ? 20 : undefined,
        backgroundColor: filteredData.map((item, index, array) => {
          const prevEl = array[index - 1]?.summary || 0;
          const currentEl = item.summary;
          const value = index > 0 ? currentEl - prevEl : 0;
          return value >= 0 ? 'rgba(144, 238, 144, 0.5)' : 'rgba(255, 99, 132, 0.5)';
        }),
        borderColor: 'transparent',
        borderWidth: 1,
        yAxisID: 'y-axis-2',
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      'y-axis-1': {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          display: false, // Убираем линии сетки для этой оси
        },
      },
      'y-axis-2': {
        type: 'linear',
        display: true,
        position: 'left',
        beginAtZero: true,
      },
    },
  };

  return (
    <ChartWrapper style={{ opacity: isLoading ? 0.4 : 1 }}>
      {isLoading ? (
        <LoadingOutlined style={{ fontSize: '40px', position: 'absolute', top: '50%' }} />
      ) : null}
      <ChartActionsWrapper>
        <ChartDays days={days} onSetDays={setDays} />
        <ButtonCSV
          onClick={() => {
            const a = convertChartToCSV({ labels, pl, summaryData });
            downloadCSV(a);
          }}
        >
          CSV <AreaChartOutlined />
        </ButtonCSV>
      </ChartActionsWrapper>
      <Line data={chartData} options={options} />
    </ChartWrapper>
  );
};

import styled from 'styled-components';

import { Tag } from 'shared/components/Atoms/Tag/Tag';

interface Props {
  tags: string[];
  activeTag: string;
  onChange: (tag: string) => void;
}

const TagsWrapper = styled.div`
  display: flex;
  text-transform: capitalize;
`;

export const Tags = ({ tags, activeTag, onChange }: Props) => {
  return (
    <TagsWrapper>
      {tags.map(tag => (
        <Tag onChange={onChange} key={tag} checked={tag === activeTag} name={tag}>
          {tag}
        </Tag>
      ))}
    </TagsWrapper>
  );
};

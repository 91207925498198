export const stableCoinList = [
  {
    id: 'tether',
    symbol: 'usdt',
    name: 'Tether',
  },
  {
    id: 'usd-coin',
    symbol: 'usdc',
    name: 'USD Coin',
  },
  {
    id: 'dai',
    symbol: 'dai',
    name: 'Dai',
  },
  {
    id: 'true-usd',
    symbol: 'tusd',
    name: 'TrueUSD',
  },
  {
    id: 'binance-usd',
    symbol: 'busd',
    name: 'Binance USD',
  },
  {
    id: 'usdd',
    symbol: 'usdd',
    name: 'USDD',
  },
  {
    id: 'frax',
    symbol: 'frax',
    name: 'Frax',
  },
  {
    id: 'paxos-standard',
    symbol: 'usdp',
    name: 'Pax Dollar',
  },
  {
    id: 'pax-gold',
    symbol: 'paxg',
    name: 'PAX Gold',
  },
  {
    id: 'tether-gold',
    symbol: 'xaut',
    name: 'Tether Gold',
  },
  {
    id: 'first-digital-usd',
    symbol: 'fdusd',
    name: 'First Digital USD',
  },
  {
    id: 'gemini-dollar',
    symbol: 'gusd',
    name: 'Gemini Dollar',
  },
  {
    id: 'liquity-usd',
    symbol: 'lusd',
    name: 'Liquity USD',
  },
  {
    id: 'tether-eurt',
    symbol: 'eurt',
    name: 'Euro Tether',
  },
  {
    id: 'alchemix-usd',
    symbol: 'alusd',
    name: 'Alchemix USD',
  },
  {
    id: 'stasis-eurs',
    symbol: 'eurs',
    name: 'STASIS EURO',
  },
  {
    id: 'terrausd',
    symbol: 'ustc',
    name: 'TerraClassicUSD',
  },
  {
    id: 'crvusd',
    symbol: 'crvusd',
    name: 'crvUSD',
  },
  {
    id: 'eusd-27a558b0-8b5b-4225-a614-63539da936f4',
    symbol: 'eusd',
    name: 'eUSD (OLD)',
  },
  {
    id: 'bilira',
    symbol: 'tryb',
    name: 'BiLira',
  },
  {
    id: 'magic-internet-money',
    symbol: 'mim',
    name: 'Magic Internet Money',
  },
  {
    id: 'flex-usd',
    symbol: 'flexusd',
    name: 'flexUSD',
  },
  {
    id: 'xsgd',
    symbol: 'xsgd',
    name: 'XSGD',
  },
  {
    id: 'helio-protocol-hay',
    symbol: 'hay',
    name: 'Destablecoin HAY',
  },
  {
    id: 'dola-usd',
    symbol: 'dola',
    name: 'DOLA',
  },
  {
    id: 'nusd',
    symbol: 'susd',
    name: 'sUSD',
  },
  {
    id: 'bean',
    symbol: 'bean',
    name: 'Bean',
  },
  {
    id: 'bob',
    symbol: 'bob',
    name: 'BOB',
  },
  {
    id: 'fei-usd',
    symbol: 'fei',
    name: 'Fei USD',
  },
  {
    id: 'token-dforce-usd',
    symbol: 'usx',
    name: 'dForce USD',
  },
  {
    id: 'mimatic',
    symbol: 'mimatic',
    name: 'MAI',
  },
  {
    id: 'r',
    symbol: 'r',
    name: 'R',
  },
  {
    id: 'tor',
    symbol: 'tor',
    name: 'TOR',
  },
  {
    id: 'ageur',
    symbol: 'ageur',
    name: 'agEUR',
  },
  {
    id: 'zusd',
    symbol: 'zusd',
    name: 'ZUSD',
  },
  {
    id: 'origin-dollar',
    symbol: 'ousd',
    name: 'Origin Dollar',
  },
  {
    id: 'celo-euro',
    symbol: 'ceur',
    name: 'Celo Euro',
  },
  {
    id: 'gyen',
    symbol: 'gyen',
    name: 'GYEN',
  },
  {
    id: 'yusd-stablecoin',
    symbol: 'yusd',
    name: 'YUSD Stablecoin',
  },
  {
    id: 'usdx',
    symbol: 'usdx',
    name: 'USDX',
  },
  {
    id: 'wemix-dollar',
    symbol: 'wemix$',
    name: 'WEMIX Dollar',
  },
  {
    id: 'husd',
    symbol: 'husd',
    name: 'HUSD',
  },
  {
    id: 'vesta-stable',
    symbol: 'vst',
    name: 'Vesta Stable',
  },
  {
    id: 'celo-dollar',
    symbol: 'cusd',
    name: 'Celo Dollar',
  },
  {
    id: 'straitsx-indonesia-rupiah',
    symbol: 'xidr',
    name: 'XIDR',
  },
  {
    id: 'vai',
    symbol: 'vai',
    name: 'Vai',
  },
  {
    id: 'djed',
    symbol: 'djed',
    name: 'Djed',
  },
  {
    id: 'seur',
    symbol: 'seur',
    name: 'sEUR',
  },
  {
    id: 'musd',
    symbol: 'musd',
    name: 'mStable USD',
  },
  {
    id: 'cnh-tether',
    symbol: 'cnht',
    name: 'CNH Tether',
  },
  {
    id: 'usdp',
    symbol: 'usdp',
    name: 'USDP Stablecoin',
  },
  {
    id: 'par-stablecoin',
    symbol: 'par',
    name: 'Parallel',
  },
  {
    id: 'equilibrium-eosdt',
    symbol: 'eosdt',
    name: 'Equilibrium EOSDT',
  },
  {
    id: 'sperax-usd',
    symbol: 'usds',
    name: 'Sperax USD',
  },
  {
    id: 'spiceusd',
    symbol: 'usds',
    name: 'SpiceUSD',
  },
  {
    id: 'coin98-dollar',
    symbol: 'cusd',
    name: 'Coin98 Dollar',
  },
  {
    id: 'usdk',
    symbol: 'usdk',
    name: 'USDK',
  },
  {
    id: 'reserve',
    symbol: 'rsv',
    name: 'Reserve',
  },
  {
    id: 'zasset-zusd',
    symbol: 'zusd',
    name: 'Zasset zUSD',
  },
  {
    id: 'basis-cash',
    symbol: 'bac',
    name: 'Basis Cash',
  },
  {
    id: 'statik',
    symbol: 'statik',
    name: 'Statik',
  },
  {
    id: 'one-cash',
    symbol: 'onc',
    name: 'One Cash',
  },
  {
    id: 'dynamic-set-dollar',
    symbol: 'dsd',
    name: 'Dynamic Set Dollar',
  },
  {
    id: 'usd-balance',
    symbol: 'usdb',
    name: 'USD Balance',
  },
  {
    id: 'binance-peg-busd',
    symbol: 'busd',
    name: 'Binance-Peg BUSD',
  },
  {
    id: 'bdollar',
    symbol: 'bdo',
    name: 'bDollar',
  },
  {
    id: 'xdai',
    symbol: 'xdai',
    name: 'XDAI',
  },
  {
    id: 'monerium-eur-money',
    symbol: 'eure',
    name: 'Monerium EUR emoney',
  },
  {
    id: 'klaytn-dai',
    symbol: 'kdai',
    name: 'Klaytn Dai',
  },
  {
    id: 'paypal-usd',
    symbol: 'pyusd',
    name: 'PayPal USD',
  },
  {
    id: 'jpy-coin',
    symbol: 'jpyc',
    name: 'JPY Coin',
  },
  {
    id: 'sora-synthetic-usd',
    symbol: 'xstusd',
    name: 'SORA Synthetic USD',
  },
  {
    id: 'jpyc',
    symbol: 'jpyc',
    name: 'JPY Coin v1',
  },
  {
    id: 'synth-ousd',
    symbol: 'ousd',
    name: 'Synth oUSD',
  },
  {
    id: 'jarvis-synthetic-swiss-franc',
    symbol: 'jchf',
    name: 'Jarvis Synthetic Swiss Franc',
  },
  {
    id: 'jarvis-synthetic-euro',
    symbol: 'jeur',
    name: 'Jarvis Synthetic Euro',
  },
  {
    id: 'iron-stablecoin',
    symbol: 'iron',
    name: 'Iron',
  },
  {
    id: 'iron-bsc',
    symbol: 'iron',
    name: 'Iron BSC',
  },
  {
    id: 'eusd-new',
    symbol: 'eusd',
    name: 'eUSD',
  },
  {
    id: 'truecnh',
    symbol: 'tcnh',
    name: 'TrueCNH',
  },
  {
    id: 'decentralized-usd',
    symbol: 'dusd',
    name: 'Decentralized USD',
  },
  {
    id: 'utopia-usd',
    symbol: 'uusd',
    name: 'Utopia USD',
  },
  {
    id: 'cad-coin',
    symbol: 'cadc',
    name: 'CAD Coin',
  },
  {
    id: 'wrapped-ust',
    symbol: 'ustc',
    name: 'Wrapped USTC',
  },
  {
    id: 'offshift-anonusd',
    symbol: 'anonusd',
    name: 'Offshift anonUSD',
  },
  {
    id: 'xdollar-stablecoin',
    symbol: 'xusd',
    name: 'xDollar Stablecoin',
  },
];

import { LinkOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import styled from 'styled-components';

import { DEBANK_PROTOCOL_ALL_LIST } from 'shared/components/Networks/networks';
import { formatUsd } from 'shared/helpers/formatUsd';
import { getBalance } from 'shared/helpers/getBalance';

function toBigInt(x: number | bigint) {
  try {
    return BigInt(x); // dont use == because 7 == 7n but 7 !== 7n
  } catch (error) {
    return x; // conversion to BigInt failed, surely it is not a BigInt
  }
}

export const LinkName = styled.div``;
export const columns = [
  // {
  //   title: 'Coingecko Id',
  //   dataIndex: 'coingecko_id',
  //   key: 'coingecko_id',
  //   render: (_, { coingecko_id }) => coingecko_id,
  // },
  {
    title: 'Name',
    dataIndex: 'protocol',
    key: 'protocol',
    sorter: (a, b) => {
      if (a?.protocol < b?.protocol) return -1;
      if (a?.protocol > b?.protocol) return 1;
      return 0;
    },
    render: (_, { name, protocol }) => {
      const project = DEBANK_PROTOCOL_ALL_LIST.find(
        item => item.name === protocol || item.name === name,
      );

      return (
        <LinkName>
          {project ? (
            <a href={project?.site_url} target="_blank">
              {protocol} {name ? `(${name})` : ''} <LinkOutlined />
            </a>
          ) : (
            `${protocol} ${name ? `(${name})` : ''}`
          )}
        </LinkName>
      );
    },
  },
  {
    title: 'Pool',
    dataIndex: 'pool',
    key: 'pool',
    render: (_, { supply_token_list }) => (
      <>
        {[...(supply_token_list || [])]
          .sort(({ amount = 0 }, { amount: am }) => am - amount)
          .map(({ logo_url }) => (
            <Image key={logo_url} width={20} preview={false} src={logo_url} />
          ))}
      </>
    ),
  },
  {
    title: 'Balance',
    key: 'balance',
    ellipsis: true,
    render: (_, { supply_token_list }) => (
      <>
        {[...(supply_token_list || [])]
          .sort((a, b) => b.amount - a.amount)
          // .filter(({ amount }) => amount > 0)
          .map(({ optimized_symbol, amount }) => (
            <div key={optimized_symbol}>
              {Number(amount).toFixed(2)} {optimized_symbol}{' '}
            </div>
          ))}
      </>
    ),
  },
  {
    title: 'Rewards',
    dataIndex: 'rewards',
    key: 'rewards',
    align: 'left',
    render: (_, { rewards }) =>
      rewards?.map(({ amount, name, price }, index) => {
        return (
          <span key={`rewards-${index}`}>
            {Number(amount).toFixed(5)} {name} ($
            {Math.floor(+amount * +price).toFixed(0)})
            <br />
          </span>
        );
      }),
    responsive: ['sm'],
  },
  {
    title: 'Borrowed',
    dataIndex: 'borrowed',
    key: 'borrowed',
    align: 'left',
    sorter: (a, b) => a?.borrowed - b.borrowed,
    render: (_, { borrowed }) =>
      borrowed?.map(({ amount, name, price }, index) => {
        return (
          <span key={`borrowed-${index}`}>
            {Number(amount).toFixed(5)} ($
            {Math.floor(+amount * +price).toFixed(0)}) {name}
            <br />
          </span>
        );
      }),
    responsive: ['sm'],
  },
  {
    title: 'Health rate',
    dataIndex: 'health_rate',
    key: 'health_rate',
    align: 'center',
    sorter: (a, b) => a?.health_rate - b?.health_rate,
    render: (_, { health_rate }) => {
      if (health_rate === null) {
        return null;
      }
      return typeof toBigInt(+health_rate) === 'bigint' || +health_rate > 100
        ? `>100`
        : health_rate?.toString()?.slice(0, 4);
    },
    responsive: ['sm'],
  },
  {
    title: 'USD value',
    dataIndex: 'totalUsd',
    key: 'totalUsd',
    align: 'right',
    render: (_, { totalUsd, borrowed, rewards }) => {
      return <div>{`${formatUsd(getBalance(totalUsd, borrowed, rewards))}`}</div>;
    },
    defaultSortOrder: 'descend',
    sorter: (a: any, b: any) => a.totalUsd - b.totalUsd,
  },
];

import { useQuery } from '@apollo/client';
import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled, { keyframes } from 'styled-components';

import { GET_NFT } from 'shared/components/Accounts/Accounts.graphql';
import { DEBANK_PROTOCOL_LIST } from 'shared/components/Networks/networks';
import NFTImge from 'shared/images/NFT.svg';
import { nftListAtom } from 'shared/recoil/atoms/nfts';

interface Props {
  chainName: string;
  contractAddress: string;
  tokenId: string;
  isScrolling: boolean;
  address: string;
}

const gradientShift = keyframes`
  0% {
    background-position: 100% 50%, 0% 50%;
  }
  100% {
    background-position: 0% 50%, 100% 50%;
  }
`;

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #dfdfdf;
  justify-content: end;
  width: 271px;
  height: 371px;
`;

const ImgStyled = styled.img`
  border-radius: 8px;
  border: 1px solid #dfdfdf;
  width: 239px;
  height: 239px;
  object-fit: contain;
  margin-bottom: auto;
`;

const VideoStyled = styled.video`
  border-radius: 8px;
  border: 1px solid #dfdfdf;
  width: 239px;
  height: 239px;
  object-fit: contain;
  margin-bottom: auto;
`;

const Name = styled.a`
  margin-top: 16px;
  font-size: 17px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const CollectionName = styled.a`
  font-weight: 500;
  color: gray;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ImgNotLoaded = styled.div`
  display: flex;
  flex-direction: column;
  width: 239px;
  height: 239px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid rgb(223, 223, 223);
  margin-bottom: auto;
`;

const ImgNotLoadedText = styled.span`
  font-weight: 500;
  font-size: 14px;
  margin-top: 16px;
`;

const ImgLoading = styled.div`
  width: 239px;
  height: 239px;
  border-radius: 8px;
  border: 1px solid rgb(223, 223, 223);
  background-size: 200% 100%, 200% 100%; // Удвоим размер фона по горизонтали для анимации
  background-image: linear-gradient(90deg, rgb(223, 223, 223), rgb(223, 223, 223)),
    linear-gradient(90deg, rgb(233, 233, 233) 0%, rgb(245, 245, 245) 65.1%, rgb(233, 233, 233) 100%);
  animation: ${gradientShift} 5s infinite alternate;
  margin-bottom: auto;
`;

const NameLoading = styled.div`
  width: 130px;
  height: 22px;
  border-radius: 8px;
  gap: 8px;
  margin: 16px 0 4px 0;
  background-size: 200% 100%, 200% 100%; // Удвоим размер фона по горизонтали для анимации
  background-image: linear-gradient(90deg, rgb(223, 223, 223), rgb(223, 223, 223)),
    linear-gradient(90deg, rgb(233, 233, 233) 0%, rgb(245, 245, 245) 65.1%, rgb(233, 233, 233) 100%);
  animation: ${gradientShift} 5s infinite alternate;
`;

const CollectionNameLoading = styled.div`
  width: 94px;
  height: 22px;
  border-radius: 8px;
  gap: 8px;
  background-size: 200% 100%, 200% 100%; // Удвоим размер фона по горизонтали для анимации
  background-image: linear-gradient(90deg, rgb(223, 223, 223), rgb(223, 223, 223)),
    linear-gradient(90deg, rgb(233, 233, 233) 0%, rgb(245, 245, 245) 65.1%, rgb(233, 233, 233) 100%);
  animation: ${gradientShift} 5s infinite alternate;
`;

const NetworkIcon = styled.img`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 5px;
  left: 5px;
  border: 0;
  border-radius: 50%;
  overflow: hidden;
`;

function ipfsToWeb(ipfsUrl) {
  if (ipfsUrl.startsWith('ipfs://')) {
    return 'https://ipfs.io/ipfs/' + ipfsUrl.substring(7);
  }
  return ipfsUrl;
}

const NETWORKS_MAP: Record<string, string> = {
  mainnet: 'ethereum',
};

const Verified = styled.div`
  color: white;
  background: #2d48f1;
  position: absolute;
  top: 7px;
  border-radius: 10px;
  padding: 5px;
  font-size: 7px;
  right: 7px;
`;

const NotVerified = styled.div`
  color: white;
  background: gray;
  position: absolute;
  top: 7px;
  border-radius: 10px;
  padding: 5px;
  font-size: 7px;
  right: 7px;
`;

const DEBANK_NETWORKS_MAP = {
  mainnet: 'eth',
  arbitrum: 'arb',
  optimism: 'op',
  avalanche: 'avax',
  gnosis: 'xdai',
  fantom: 'ftm',
  polygon: 'matic',
  opbnb: 'bsc',
};
export const NftCard = ({
  chainName,
  contractAddress,
  tokenId,
  isScrolling,
  address,
  floorPrice,
}: Props) => {
  const [nftList, setnftList] = useRecoilState(nftListAtom);

  const networkObject = DEBANK_PROTOCOL_LIST.find(
    ({ id }) => id === (DEBANK_NETWORKS_MAP?.[chainName] || chainName),
  );

  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
  };
  const key = `${chainName}_${contractAddress}_${tokenId}`;
  const data = nftList.find(({ nftKey }) => nftKey === key);
  const { data: nftData, stopPolling } = useQuery(GET_NFT, {
    skip: !!data,
    pollInterval: 3000,
    variables: {
      chainName,
      contractAddress,
      tokenId,
    },
  });

  useEffect(() => {
    if (data) {
      stopPolling();
    }
  }, [data]);

  useEffect(() => {
    if (nftData?.nft && !data) {
      setnftList(prev => [...prev, { nft: nftData.nft, nftKey: key }]);
      stopPolling();
    }
  }, [nftData?.nft]);

  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  const nameValue = data?.nft?.name || data?.nft?.id || data?.nft?.collection_name || 'Nft link';
  const collectionName = data?.nft?.collection_name || 'Collection link';

  return (
    <CardWrapper>
      {networkObject ? (
        <NetworkIcon src={networkObject?.logo_url} alt={networkObject?.name || chainName || ''} />
      ) : null}
      {data?.nft && !isScrolling ? (
        <>
          {/*{data?.nft?.collection_verified ? (*/}
          {/*  <Verified>Verified</Verified>*/}
          {/*) : (*/}
          {/*  <NotVerified>Not verified</NotVerified>*/}
          {/*)}*/}
          {imgError || (data?.nft && !data?.nft?.image_url && !data?.nft?.animation_url) ? (
            <ImgNotLoaded>
              <NFTImge />
              <ImgNotLoadedText>Can’t load NFT, but it’s here</ImgNotLoadedText>
            </ImgNotLoaded>
          ) : (
            <>
              {/*{data?.nft?.collection_verified ? (*/}
              {/*  <Verified>Verified</Verified>*/}
              {/*) : (*/}
              {/*  <NotVerified>Not verified</NotVerified>*/}
              {/*)}*/}
              {(data?.nft?.image_url && data?.nft?.image_url?.startsWith('data:image')) ||
              data?.nft?.image_url?.startsWith('ipfs') ||
              data?.nft?.image_url?.includes('http') ||
              data?.nft?.image_url?.includes('https') ? (
                <ImgStyled
                  className="image"
                  onError={handleError}
                  src={ipfsToWeb(data?.nft?.image_url)}
                  alt="image_url"
                />
              ) : null}
              {data?.nft?.image_url && data?.nft?.image_url?.startsWith('data:video') ? (
                <VideoStyled
                  className="video"
                  onError={handleError}
                  autoPlay={true}
                  loop={true}
                  muted={true}
                >
                  <source src={data?.nft?.animation_url || data?.nft?.image_url} />
                  Ваш браузер не поддерживает элемент видео.
                </VideoStyled>
              ) : null}
            </>
          )}
          {floorPrice ? (
            <div>
              Floor price: <strong>{floorPrice} $</strong>
            </div>
          ) : null}
          <Tooltip title={nameValue}>
            <Name
              target="_blank"
              href={`https://opensea.io/assets/${
                chainName ? NETWORKS_MAP?.[chainName] || chainName : chainName
              }/${contractAddress}/${tokenId}`}
            >
              {nameValue}
            </Name>
          </Tooltip>
          <div>
            from{' '}
            <Tooltip title={collectionName}>
              <CollectionName
                target="_blank"
                href={`https://opensea.io/assets/${
                  chainName ? NETWORKS_MAP?.[chainName] || chainName : chainName
                }/${contractAddress}`}
              >
                {collectionName}
              </CollectionName>
            </Tooltip>
          </div>
        </>
      ) : (
        <>
          <ImgLoading />
          <NameLoading />
          <CollectionNameLoading />
        </>
      )}
    </CardWrapper>
  );
};

import { Space, Switch } from 'antd';
import {
  Chart,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { stableCoinList } from 'shared/components/HeatMap/stableCoinList';
import { getIconSrc } from 'shared/helpers/url';

Chart.register(
  TreemapController,
  TreemapElement,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
);
const ChartWrapper = styled.div`
  height: 400px;

  .chart-fake {
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  .tooltipBody {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }

  .label {
    font-weight: bold;
    padding-right: 5px;
  }
`;

const getOrCreateTooltip = chart => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = context => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  const { dataset, dataIndex } = tooltip.dataPoints[0];
  const pointData = dataset.tree[dataIndex];

  const ul = document.createElement('ul');
  ul.className = 'tooltipBody';

  if (pointData) {
    const logoUrl = pointData.logo_url;
    const { token, coingecko_id, network, priceUsd, totalUsd, protocol, day_change_percent_usd } =
      pointData;

    const dayChangePercentUsd = +(day_change_percent_usd?.toFixed(1) || 0);
    const li1 = document.createElement('li');
    const img = document.createElement('img');
    img.src = logoUrl || getIconSrc(token);
    img.height = 20;
    img.style.borderRadius = '50%';
    img.style.marginRight = '10px';
    li1.appendChild(img);
    const span1 = document.createElement('span');
    span1.className = 'black';
    span1.textContent = `${protocol || token}`;
    li1.appendChild(span1);
    ul.appendChild(li1);

    if (coingecko_id && !coingecko_id?.includes('custom') && !protocol) {
      const li3 = document.createElement('li');
      li3.innerHTML = `<span class="label">Coingecko ID:</span><span class="black">${coingecko_id}</span>`;
      ul.appendChild(li3);
    }

    if (priceUsd) {
      const li2 = document.createElement('li');
      li2.innerHTML = `<span class="label">Day change:</span><span class="black">${dayChangePercentUsd}%</span>`;
      ul.appendChild(li2);
    }

    const li4 = document.createElement('li');
    li4.innerHTML = `<span class="label">Total:</span><span class="black">$${(
      totalUsd || 0
    ).toFixed(2)}</span>`;
    ul.appendChild(li4);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  tooltipEl.innerHTML = '';
  tooltipEl.appendChild(ul);
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = '10px';
  tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
  tooltipEl.style.borderRadius = '3px';
  tooltipEl.style.color = 'white';
  tooltipEl.style.opacity = 1;
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.transform = 'translate(-50%, 0)';
  tooltipEl.style.transition = 'all .1s ease';
  tooltipEl.style.width = '100%';
  tooltipEl.style['max-width'] = 'max-content';
  tooltipEl.style['border-radius'] = '8px';
  tooltipEl.style['z-index'] = 1;
};

function formatNumber(number) {
  try {
    const parts = number?.toFixed(2).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  } catch (error) {
    return parseFloat(number)?.toFixed(2);
  }
}

function getColorForValue(value: number) {
  const colors = [
    { value: -15, color: '#941f1d' },
    { value: -10, color: '#c2373f' },
    { value: -5, color: '#ee444e' },
    { value: -0.3, color: '#ee444e' },
    { value: 0, color: '#eef4fa' },
    { value: 0.3, color: '#16c784' },
    { value: 5, color: '#16c784' },
    { value: 10, color: '#149c68' },
    { value: 15, color: '#076a4d' },
  ];

  // Если значение больше максимального или меньше минимального,
  // верните соответствующий цвет.
  if (value > colors[colors.length - 1].value) {
    return colors[colors.length - 1].color;
  }
  if (value < colors[0].value) {
    return colors[0].color;
  }

  let startColor, endColor, foundIndex;

  for (let i = 0; i < colors.length - 1; i++) {
    if (value >= colors[i].value && value <= colors[i + 1].value) {
      startColor = colors[i].color;
      endColor = colors[i + 1].color;
      foundIndex = i; // Сохраняем индекс
      break;
    }
  }

  const t =
    (value - colors?.[foundIndex].value) /
    (colors?.[foundIndex + 1].value - colors?.[foundIndex].value);
  const interpolatedColors = linearColorTween(startColor, endColor, 1);
  return interpolatedColors[Math.round(t)];
}

function linearColorTween(startColor, endColor, step) {
  const start = hexToRgb(startColor);
  const end = hexToRgb(endColor);
  const gradientSteps = [];

  for (let i = 0; i <= step; i++) {
    const r = start.r + ((end.r - start.r) / step) * i;
    const g = start.g + ((end.g - start.g) / step) * i;
    const b = start.b + ((end.b - start.b) / step) * i;
    gradientSteps.push(rgbToHex(Math.round(r), Math.round(g), Math.round(b)));
  }

  return gradientSteps;
}

function hexToRgb(hex) {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return { r, g, b };
}

function rgbToHex(r, g, b) {
  return (
    '#' +
    [r, g, b]
      .map(c => {
        const hex = c.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')
  );
}

function mergeItems(arr, mergePairs) {
  if (!mergePairs) {
    return arr;
  }
  // Создаём копию исходного массива, чтобы не модифицировать его
  const resultArray = [...arr]; // Создаем копию исходного массива

  for (const [source, target] of Object.entries(mergePairs)) {
    const targetIndex = resultArray.findIndex(item => item.coingecko_id === target);
    const sourceIndex = resultArray.findIndex(item => item.coingecko_id === source);

    if (targetIndex !== -1 && sourceIndex !== -1 && targetIndex !== sourceIndex) {
      // Создаем копии объектов перед их изменением
      const newTargetObject = { ...resultArray[targetIndex] };
      const newSourceObject = { ...resultArray[sourceIndex] };

      // Обновляем значения полей для нового объекта
      newTargetObject.amount += newSourceObject.amount;
      newTargetObject.totalUsd += newSourceObject.totalUsd;

      // Заменяем старый объект новым в результирующем массиве
      resultArray[targetIndex] = newTargetObject;

      // Удалить исходный объект из массива
      resultArray.splice(sourceIndex, 1);
    }
  }

  return mergeStableCoins([...resultArray], stableCoinList);
}

function mergeStableCoins(arr, stableCoins) {
  const stableCoinIds = stableCoins.map(coin => coin.id);

  let stableAmount = 0;
  let stableSummaryBalanceUsd = 0;

  const resultArray = arr.reduce((acc, item) => {
    // Создаем новую копию текущего объекта
    const newItem = { ...item };

    if (stableCoinIds.includes(newItem.coingecko_id)) {
      // Суммируем значения для стейблкоинов
      stableAmount += newItem.amount || 0;
      stableSummaryBalanceUsd += newItem.totalUsd || 0;
    } else {
      // Добавляем объект в результирующий массив, если это не стейблкоин
      acc.push(newItem);
    }

    return acc;
  }, []);

  // Добавляем объединенный объект "Stable coins" в результирующий массив
  resultArray.push({
    name: 'Stable coins',
    token: 'Stable coins',
    amount: stableAmount,
    totalUsd: stableSummaryBalanceUsd,
  });

  return resultArray;
}

export const HeatMap = ({ accountSummaryAssets, keyMap }) => {
  const [showIdenticalTokens, setShowIdenticalTokens] = useState(false);
  const [showProtocolTokens, setShowProtocolTokens] = useState(true);
  const [scale, setScale] = useState(true);
  const [showUsd, setShowUsd] = useState(true);
  const tokens = accountSummaryAssets?.tokens || [];
  const protocols = accountSummaryAssets?.protocols || [];
  const protocolTokens = accountSummaryAssets?.protocolTokens || [];

  const mergePairs = {
    weth: 'ethereum',
    eth: 'ethereum',
    'staked-ether': 'ethereum',
    'binance-eth': 'ethereum',
    'wrapped-beacon-eth': 'ethereum',
    sweth: 'ethereum',
    sysweth: 'ethereum',
    'wrapped-steth': 'ethereum',
    seth: 'ethereum',
    'rocket-pool-eth': 'ethereum',
    'aave-interest-bearing-steth': 'ethereum',
    'origin-ether': 'ethereum',
    'aave-eth-v1': 'ethereum',
    'diversified-staked-eth': 'ethereum',
    'liquid-staked-ethereum': 'ethereum',
    seth2: 'ethereum',
    'geist-eth': 'ethereum',
    'guarded-ether': 'ethereum',
    axlweth: 'ethereum',
    'frax-ether': 'ethereum',
    'staked-frax-ether': 'ethereum',
    'gitcoin-staked-eth-index': 'ethereum',
    'coinbase-wrapped-staked-eth': 'ethereum',
    'wrapped-bitcoin': 'bitcoin',
    'bitcoin-avalanche-bridged-btc-b': 'bitcoin',
    'compound-wrapped-btc': 'bitcoin',
    'wrapped-btc-wormhole': 'bitcoin',
    'wrapped-btc-trustless-bridge': 'bitcoin',
    'bridged-wrapped-bitcoin-stargate': 'bitcoin',
    'wbtc-plenty-bridge': 'bitcoin',
    tbtc: 'bitcoin',
    'ibtc-2': 'bitcoin',
    sbtc: 'bitcoin',
    'huobi-btc': 'bitcoin',
    'kintsugi-btc': 'bitcoin',
    tzbtc: 'bitcoin',
    'binance-wrapped-btc': 'bitcoin',
    renbtc: 'bitcoin',
  };

  const tokenss = showProtocolTokens ? protocolTokens : [...tokens, ...protocols];
  const mergedTokens = showIdenticalTokens ? mergeItems(tokenss, mergePairs) : tokenss;

  const tokenItems = mergedTokens.map(({ totalUsd, ...item }) => ({
    ...item,
    totalUsd,
    scale: Math.sqrt(Math.sqrt(totalUsd * totalUsd * totalUsd)),
  }));

  useEffect(() => {
    let chart = null;

    const greens = linearColorTween('#19c784', '#066b4d', 30);
    const reds = linearColorTween('#ee444e', '#941f1c', 30);
    // const greens = linearColorTween('#549845', '#2c643b', 11);
    // const reds = linearColorTween('#e43422', '#8d2b2d', 11);

    function getColor(n) {
      const s = Math.abs(n);
      const c = s > 3 ? 3 : s;
      if (n === 0) {
        return '#EEF4FA';
      }
      return getColorForValue(n);
      // if (n > 0) {
      //   return greens[Math.round((c / 3) * 10)];
      // }
      // return reds[Math.round((c / 3) * 10)];
    }

    function getHoverColor(n) {
      const s = Math.abs(n);
      const c = s > 3 ? 3 : s;
      const colorIntensity = Math.round((c / 3) * 10);
      if (n === 0) {
        return '#EEF4FA';
      }
      if (n > 0) {
        const color = greens[colorIntensity];
        return lightenColor(color, 20); // lighten by 40%
      }
      const color = reds[colorIntensity];
      return lightenColor(color, 20); // lighten by 40%
    }

    function lightenColor(color, percent) {
      const num = parseInt(color.slice(1), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        G = ((num >> 8) & 0x00ff) + amt,
        B = (num & 0x0000ff) + amt;
      return (
        '#' +
        (
          0x1000000 +
          (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
          (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
          (B < 255 ? (B < 1 ? 0 : B) : 255)
        )
          .toString(16)
          .slice(1)
          .toUpperCase()
      );
    }

    function getSize(w) {
      return w * 0.1;
    }

    async function showHeartmap(coins) {
      const canvas = document.getElementById(keyMap);
      const ctx = canvas.getContext('2d');
      chart = new Chart(ctx, {
        type: 'treemap',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              position: 'nearest',
              external: externalTooltipHandler,
            },
          },
        },
        data: {
          datasets: [
            {
              tree: coins,
              key: scale ? 'scale' : 'totalUsd',
              // treeLeafKey: scale ? 'scale' : 'totalUsd',
              spacing: 1,
              groups: [],
              backgroundColor(ctx) {
                if (ctx.type !== 'data') {
                  return;
                }
                const coin = coins[ctx.dataIndex];
                const coinDayChange = +(coin.day_change_percent_usd || 0).toFixed(1);
                return getColor(coinDayChange > 0.2 || coinDayChange < -0.2 ? coinDayChange : 0);
              },
              hoverBackgroundColor(ctx) {
                if (ctx.type !== 'data') {
                  return;
                }
                const coin = coins[ctx.dataIndex];
                const coinDayChange = +(coin.day_change_percent_usd || 0).toFixed(1);
                return getHoverColor(coinDayChange);
              },
              captions: {
                align: 'center',
              },
              labels: {
                display: true,
                position: 'middle',
                hoverColor: ['white', 'white', 'white'],
                color: ctx => {
                  const coin = coins[ctx.dataIndex];
                  const coinDayChange = +(coin.day_change_percent_usd || 0).toFixed(1);
                  return coinDayChange > 0.2 || coinDayChange < -0.2
                    ? ['white', 'white', 'white']
                    : ['black', 'black', 'black'];
                },
                font: ctx => {
                  if (ctx.type !== 'data') {
                    return;
                  }
                  const coin = coins[ctx.dataIndex];
                  const item = ctx.dataset.data[ctx.dataIndex];
                  return [
                    {
                      size: getSize(Math.min(item.w, item.h)),
                      weight: 'bold',
                    },
                    {
                      size: getSize(Math.min(item.w, item.h)) * 0.5,
                      weight: 'normal',
                    },
                    {
                      size: getSize(Math.min(item.w, item.h)) * 0.5,
                      weight: 'normal',
                    },
                  ];
                },
                formatter: ctx => {
                  if (ctx.type !== 'data') {
                    return;
                  }
                  const coin = coins[ctx.dataIndex];
                  const coinDayChange = +(coin.day_change_percent_usd || 0).toFixed(1);
                  const dominance = coin?.totalUsd
                    ? (
                        (coin?.totalUsd /
                          tokenItems.reduce((sum, { totalUsd = 0 }) => sum + +totalUsd, 0)) *
                        100
                      )?.toFixed(2)
                    : 0;
                  const item = ctx.dataset.data[ctx.dataIndex];
                  return Math.min(item.w, item.h) < 50
                    ? [(coin?.protocol || coin?.token)?.toUpperCase()]
                    : Math.min(item.w, item.h) < 100
                    ? [
                        (coin?.protocol || coin?.token)?.toUpperCase(),
                        `$${(+coin?.totalUsd || 0)?.toFixed(2)}`,
                      ]
                    : [
                        (coin?.protocol || coin?.token)?.toUpperCase(),
                        showUsd ? `$${(+coin?.totalUsd || 0)?.toFixed(2)}` : `${dominance}%`,
                        `${coinDayChange}%`,
                      ];
                },
              },
            },
          ],
        },
      });
      window.addEventListener('resize', () => chart.resize());
    }

    async function main() {
      // delay to show loading
      const loading = document.getElementById('loading');
      loading.style.visibility = 'hidden';
      setTimeout(() => {
        loading.style.visibility = 'visible';
      }, 500);
      // fetch data
      const coins = tokenItems.sort((a, b) => b.totalUsd - a.totalUsd);
      const box = document.getElementById('box');
      box.style.background = '#fff';
      if (coins.length > 0) {
        showHeartmap(coins);
      } else {
        box.innerText = 'Loading failed, please try again later.';
      }
    }

    if (tokenItems.length) {
      main();
    }

    return () => {
      chart?.destroy();
    };
  }, [tokenItems.length, showUsd, scale, showIdenticalTokens, showProtocolTokens]);

  return (
    <Wrapper>
      <div
        style={{
          gap: '16px',
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <Space align="center">
          Scale
          <Switch
            checked={scale}
            prefixCls="Scale"
            title="Scale"
            onChange={value => {
              setScale(value);
            }}
          />
        </Space>
        <Space align="center">
          Show USD
          <Switch
            checked={showUsd}
            prefixCls="ShowUSD"
            title="ShowUSD"
            onChange={value => {
              setShowUsd(value);
            }}
          />
        </Space>
        <Space align="center">
          Protocol tokens
          <Switch
            checked={showProtocolTokens}
            prefixCls="Protocoltokens"
            title="Protocoltokens"
            onChange={value => {
              setShowProtocolTokens(value);
            }}
          />
        </Space>
        <Space align="center">
          Identical tokens
          <Switch
            checked={showIdenticalTokens}
            prefixCls="Identicaltokens"
            title="Identicaltokens"
            onChange={value => {
              setShowIdenticalTokens(value);
            }}
          />
        </Space>
      </div>
      <div id="loading">
        <div id="spinner"></div>
      </div>
      <div id="box" style={{ height: 450 }}>
        <canvas height={450} id={keyMap}></canvas>
      </div>
    </Wrapper>
  );
};

import styled from 'styled-components';

import { ExchangeIcon } from 'shared/components/Exchages/ExchangeIcon';
import { DEBANK_PROTOCOL_LIST } from 'shared/components/Networks/networks';

import type { CSSProperties } from 'react';

interface Props {
  network?: string;
  style?: CSSProperties;
  logo_url: string;
  accountType: string;
}

export const NetworkIconWrapper = styled.div`
  position: relative;

  & .name {
    top: 0;
    display: none;
    position: absolute;
    background: white;
    padding: 2px;
    font-size: 10px;
  }

  &:hover > .name {
    display: flex;
  }
`;

export const NetworkIcon = ({ network, style, logo_url, accountType }: Props) => {
  const networkObject = DEBANK_PROTOCOL_LIST.find(({ id }) => id === network);
  return (
    <NetworkIconWrapper>
      {!networkObject?.logo_url && (
        <ExchangeIcon width={20} exchange={accountType} withDescription={false} />
      )}
      {networkObject ? (
        <>
          <img
            style={style}
            src={networkObject?.logo_url || logo_url}
            alt={networkObject?.name || network || ''}
          />
          <div className="name">{networkObject?.name || network}</div>
        </>
      ) : null}
    </NetworkIconWrapper>
  );
};

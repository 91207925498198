import { ExchangesTypes } from 'shared/components/Exchages/Exchanges.interfaces';
import Binance from 'shared/images/icons/accounts/binance.svg';
import Bitcoin from 'shared/images/icons/accounts/bitcoin.svg';
import Bitfinex from 'shared/images/icons/accounts/bitfinex.svg';
import Bybit from 'shared/images/icons/accounts/bybit.svg';
import Ethereum from 'shared/images/icons/accounts/ethereum.svg';
import Fierblocks from 'shared/images/icons/accounts/img.png';
import Kraken from 'shared/images/icons/accounts/kraken.svg';
import Kucoin from 'shared/images/icons/accounts/kucoin.svg';
import LooseWallet from 'shared/images/icons/accounts/looseWallet.svg';
import Metamask from 'shared/images/icons/accounts/metamask.svg';
import Okx from 'shared/images/icons/accounts/okx.svg';
import Solana from 'shared/images/icons/accounts/solana.svg';
import Tron from 'shared/images/icons/accounts/tron.svg';
import TrustWallet from 'shared/images/icons/accounts/trustwallet.svg';
import WalletConnect from 'shared/images/icons/accounts/walletConnect.svg';

export enum Accounts {
  Fierblocks = 'fierblocks',
  WalletConnect = 'walletConnect',
  Metamask = 'metamask',
  Trustwallet = 'trustwallet',
  Binance = 'binance',
  Bitcoin = 'bitcoin',
  Ethereum = 'ethereum',
  // Phantom = 'phantom',
  Tron = 'tron',
  Okx = 'okx',
  Solana = 'solana',
  Kraken = 'kraken',
  Kucoin = 'kucoin',
  Bitfinex = 'bitfinex',
  BinanceUS = 'binanceUS',
  Bybit = 'bybit',
  BinanceTR = 'binanceTR',
  LooseWallet = 'looseWallet',
}

export enum AccountConnectTypes {
  Wallet = 'wallet',
  Exchange = 'exchange',
  LooseWallet = 'looseWallet',
  Fierblocks = 'fierblocks',
}

export const ACCOUNT_CARD_LIST = {
  [Accounts.WalletConnect]: {
    type: AccountConnectTypes.Wallet,
    name: 'WalletConnect',
    icon: <WalletConnect />,
    background: '#F0F7FF',
    exchangeType: ExchangesTypes.Wallet,
    className: '',
    instructionSteps: [],
  },
  [Accounts.Metamask]: {
    type: AccountConnectTypes.Wallet,
    name: 'MetaMask',
    icon: <Metamask />,
    background: '#FFF5EB',
    exchangeType: ExchangesTypes.Wallet,
    className: '',
    instructionSteps: [],
  },
  [Accounts.Trustwallet]: {
    type: AccountConnectTypes.Wallet,
    name: 'TrustWallet',
    icon: <TrustWallet />,
    background: '#EDF6FF',
    exchangeType: ExchangesTypes.Wallet,
    className: '',
    instructionSteps: [],
  },
  [Accounts.Binance]: {
    type: AccountConnectTypes.Exchange,
    name: 'Binance',
    icon: <Binance />,
    background: '#FFF8E5',
    exchangeType: ExchangesTypes.Binance,
    className: '',
    instructionSteps: [
      { description: 'Login to your Binance account on your computer' },
      {
        description:
          'Click on API Management from your Profile icon dropdown menu on the top right',
      },
      {
        description:
          // eslint-disable-next-line max-len
          'In Give the API Key a Name field type in what you want to call it, ex. TrackStats Binance, then click Create',
      },
      {
        description: ' Input your Google Authentication Code (2FA) for Binance',
      },
      {
        description: 'Open your verification email Binance sent you and click Confirm new API key',
      },
      {
        description: 'Copy and paste your API and Secret Keys into the TrackStats app',
      },
    ],
  },
  [Accounts.Bitcoin]: {
    type: AccountConnectTypes.Wallet,
    name: 'Bitcoin',
    icon: <Bitcoin />,
    background: '#FFF8E5',
    exchangeType: ExchangesTypes.BtcWallet,
    className: '',
    instructionSteps: [
      {
        description: 'Open your Bitcoin wallet.',
      },
      {
        description:
          // eslint-disable-next-line max-len
          "Locate your Bitcoin address. This is usually found by clicking on \"Receive\" or in the account details/settings section. A Bitcoin address typically starts with a '1', '3', or 'bc1'.",
      },
      {
        description:
          // eslint-disable-next-line max-len
          'Copy the address to your clipboard. You can usually do this by clicking on the address itself or there might be a specific "copy" button.',
      },
      {
        description:
          'In your portfolio TrackStats app, find the option to add a new wallet or address.',
      },
      {
        description: 'Paste your copied public address into the appropriate field.',
      },
    ],
  },
  [Accounts.Ethereum]: {
    type: AccountConnectTypes.Wallet,
    name: 'EVM',
    icon: <Ethereum />,
    background: '#F0F3FF',
    exchangeType: ExchangesTypes.Wallet,
    className: '',
    instructionSteps: [
      { description: 'Open your chosen crypto wallet.' },
      {
        description:
          // eslint-disable-next-line max-len
          'Look for a way to access your public address or account details. This is usually found by clicking on your profile or the specific cryptocurrency that you want to track',
      },
      {
        description:
          // eslint-disable-next-line max-len
          'Once you\'ve found your public address (which often starts with "0x" for Ethereum and some other blockchains), copy it.',
      },
      {
        description: 'Copy and paste your Ethereum address into the app.',
      },
    ],
  },
  // [Accounts.Phantom]: {
  //   type: AccountConnectTypes.Wallet,
  //   name: 'Phantom',
  //   icon: <Phantom />,
  //   background: '#F2F0FF',
  //   exchangeType: ExchangesTypes.Wallet,
  // },
  [Accounts.Tron]: {
    type: AccountConnectTypes.Wallet,
    name: 'Tron',
    icon: <Tron />,
    background: '#FFF1F1',
    exchangeType: ExchangesTypes.TronWallet,
    className: '',
    instructionSteps: [
      { description: 'Open your Tron Wallet.' },
      {
        description:
          // eslint-disable-next-line max-len
          "Your Tron address will be visible on the main screen or in the account details/ settings section. A Tron address typically starts with 'T'.",
      },
      {
        description:
          'Click on the address or the "copy" icon next to it to copy the address to your clipboard.',
      },
      {
        description: 'In your TrackStats app, look for the option to add a new wallet or address.',
      },
      {
        description: 'Paste your copied public address into the appropriate field.',
      },
    ],
  },
  [Accounts.Okx]: {
    type: AccountConnectTypes.Exchange,
    name: 'OKX',
    icon: <Okx />,
    background: '#F1F1F1',
    exchangeType: ExchangesTypes.OKX,
    className: '',
    instructionSteps: [
      { description: 'Login to your OKX account on your computer' },
      { description: 'Hover your profile icon on top right and select Api Management' },
      { description: 'Click on Create API' },
      {
        description:
          // eslint-disable-next-line max-len
          'Fill in API Name (ex. TrackStats OKX) and API Passphrase (the API Passphrase will be used to verify the API allocation, please record and backup it on paper or mobile terminal). Click Confirm',
      },
      {
        description:
          // eslint-disable-next-line max-len
          'Go through Security Verification by inputting your Trade Password, Email Verification Code and Google Verification Code',
      },
      {
        description:
          'Now copy and paste your API Key, API Secret and API Passphrase into TrackStats app',
      },
    ],
  },
  [Accounts.Solana]: {
    type: AccountConnectTypes.Wallet,
    name: 'Solana',
    icon: <Solana />,
    background: '#EFF3FF',
    exchangeType: ExchangesTypes.Solana,
    className: '',
    instructionSteps: [
      {
        description: 'Open your Solana Wallet.',
      },
      {
        description:
          // eslint-disable-next-line max-len
          "Look for your wallet's address at the top of the interface or in the account details/settings section. A Solana address usually starts with a set of random letters and numbers.",
      },
      {
        description:
          // eslint-disable-next-line max-len
          'Copy the address to your clipboard. In most wallets, you can do this by clicking on the address itself or there might be a specific "copy" button.',
      },
      {
        description:
          'Now go to your TrackStats app and find the option to add a new wallet or address.',
      },
      {
        description: 'Paste your copied public address into the appropriate field.',
      },
    ],
  },
  [Accounts.Kraken]: {
    type: AccountConnectTypes.Exchange,
    name: 'Kraken',
    icon: <Kraken />,
    background: '#EBF0FF',
    exchangeType: ExchangesTypes.Kraken,
    className: '',
    instructionSteps: [
      {
        description: 'Login to your Kraken account on your computer',
      },
      {
        description: 'Click on your name at top right corner',
      },
      {
        description: 'Select API from the Security dropdown and click on Add key button',
      },
      {
        description:
          // eslint-disable-next-line max-len
          'Give permission to Query Funds, Query Open Orders & Trades and Query Closed Orders & Trades and click Generate Key button',
      },
      {
        description:
          'Below the message Success: Created API key you will see your API and Private Keys',
      },
      {
        description:
          // eslint-disable-next-line max-len
          'Please note, that in security settings two-factor authentication for API keys must be set to No method in order to be able to sync to TrackStats',
      },
      {
        description:
          // eslint-disable-next-line max-len
          'Copy and paste your API Key and API Secret (Private) into TrackStats app',
      },
    ],
  },
  [Accounts.Kucoin]: {
    type: AccountConnectTypes.Exchange,
    name: 'KuCoin',
    icon: <Kucoin />,
    background: '#E7F5F2',
    exchangeType: ExchangesTypes.KuCoin,
    className: '',
    instructionSteps: [
      {
        description: 'Login to your KuCoin account on your computer',
      },
      {
        description: 'Hover your profile icon on top right and select Api Management',
      },
      {
        description: 'Click on Create API',
      },
      {
        description:
          // eslint-disable-next-line max-len
          'Fill in API Name (ex.TrackStats Kucoin) and API Passphrase (the API Passphrase will be used to verify the API allocation, please record and backup it on paper or mobile terminal). Click Confirm',
      },
      {
        description:
          // eslint-disable-next-line max-len
          'Go through Security Verification by inputting your Trade Password, Email Verification Code and Google Verification Code',
      },
      {
        description:
          'Copy and paste your API Key, API Secret and API Passphrase into TrackStats app',
      },
    ],
  },
  [Accounts.Bitfinex]: {
    type: AccountConnectTypes.Exchange,
    name: 'BitFinex',
    icon: <Bitfinex />,
    background: '#EEFFD4',
    exchangeType: ExchangesTypes.BitfinexExchange,
    className: '',
    instructionSteps: [
      { description: 'Login to your Bitfinex account on your computer' },
      { description: 'Click on API Keys from your Profile icon dropdown menu on the top right' },
      {
        description:
          // eslint-disable-next-line max-len
          'Click Create New Key and Label Your API Key with any label you’d like to (ex. TrackStats Bitfinex). Make sure to give permission to Wallets and click on Generate API Key',
      },
      {
        description: 'Open the verification email Bitfinex sent you and click Create API Key',
      },
      {
        description: 'Copy and paste your API and Secret Keys into the app',
      },
    ],
  },
  [Accounts.BinanceUS]: {
    type: AccountConnectTypes.Exchange,
    name: 'Binance US',
    icon: <Binance />,
    background: '#F3F3F3',
    exchangeType: ExchangesTypes.BinanceUS,
    className: '',
    instructionSteps: [],
  },
  [Accounts.Bybit]: {
    type: AccountConnectTypes.Exchange,
    name: 'ByBit',
    icon: <Bybit />,
    background: '#F3F3F3',
    exchangeType: ExchangesTypes.Bybit,
    className: '',
    instructionSteps: [
      { description: 'Login to your ByBit account' },
      { description: 'Click your account on top right, then API' },
      { description: 'Click Create New Key' },
      { description: 'Enable 2FA if needed' },
      { description: 'Enter the name (ex. TrackStats)' },
      { description: 'Enable Read-Only permission' },
      { description: 'Then needed account permissions (SPOT account is mandatory)' },
      { description: 'Enable All IPs' },
      { description: 'Enter 2FA code and click Confirm' },
      { description: 'Copy and Paste your API Key and the API Secret Key into TrackStats' },
    ],
  },
  // [Accounts.BinanceTR]: {
  //   type: AccountConnectTypes.Exchange,
  //   name: 'Binance TR',
  //   icon: <BinanceTR />,
  //   background: '#FFF8E5',
  //   exchangeType: ExchangesTypes.BinanceTR,
  //   className: '',
  //   instructionSteps: [],
  // },
  [Accounts.LooseWallet]: {
    type: AccountConnectTypes.LooseWallet,
    name: 'Manual Portfolio',
    icon: <LooseWallet />,
    background: '#F3F3F3',
    exchangeType: ExchangesTypes.LooseWallet,
    className: 'big',
    instructionSteps: [],
  },
  [Accounts.Fierblocks]: {
    type: AccountConnectTypes.Fierblocks,
    name: 'Fierblocks',
    icon: <img width="60px" height="60px" src={Fierblocks} alt="Fierblocks" />,
    background: '#edf6ff',
    exchangeType: ExchangesTypes.Fierblocks,
    className: '',
    instructionSteps: [],
  },
};

const defaultFields = [
  // {
  //   label: 'Name:',
  //   name: 'name',
  //   rules: [{ required: true, message: 'Please input name!' }],
  // },
  {
    label: 'API key',
    name: 'api_key',
    rules: { required: true, message: 'Please input API key!' },
  },
  {
    label: 'API Secret',
    name: 'secret',
    rules: { required: true, message: 'Please input API secret!' },
    type: 'password',
  },
];

const walletFields = [
  // {
  //   label: 'Name',
  //   name: 'name',
  //   rules: { required: true, message: 'Please input name!' },
  // },
  {
    label: 'Address',
    name: 'address',
    rules: { required: true, message: 'Please input address!' },
  },
];

interface FieldType {
  label: string;
  name: string;
  type?: string;
  rules: { required: boolean; message: string };
}

export const ExchangesFieldsMap: Record<string, FieldType[]> = {
  [Accounts.Fierblocks]: [
    {
      label: 'API key',
      name: 'api_key',
      rules: { required: true, message: 'Please input API key!' },
    },
    {
      label: '',
      name: 'secret',
      rules: { required: true, message: 'Please input API secret!' },
      type: 'file',
    },
  ],
  [Accounts.Kraken]: defaultFields,
  [Accounts.Bitfinex]: defaultFields,
  [Accounts.BinanceUS]: defaultFields,
  [Accounts.BinanceTR]: defaultFields,
  [Accounts.Binance]: defaultFields,
  [Accounts.Bybit]: defaultFields,
  [Accounts.Solana]: walletFields,
  [Accounts.Ethereum]: walletFields,
  [Accounts.Bitcoin]: walletFields,
  [Accounts.Tron]: walletFields,
  [Accounts.Okx]: [
    ...defaultFields,
    {
      label: 'Passphrase:',
      name: 'passphrase',
      rules: { required: true, message: 'Please Passphrase!' },
      type: 'password',
    },
  ],
  [Accounts.Kucoin]: [
    ...defaultFields,
    {
      label: 'Passphrase:',
      name: 'passphrase',
      rules: { required: true, message: 'Please Passphrase!' },
      type: 'password',
    },
  ],
  [Accounts.LooseWallet]: [
    {
      label: 'Name:',
      name: 'name',
      type: 'text',
      rules: { required: true, message: 'Please input name!' },
    },
  ],
};

export const ACCOUNTS_WITH_PASSPHRASE = [Accounts.Okx, Accounts.Kucoin];

import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { AccountCard } from 'shared/components/ConnectAssets/components/AccountCards/components/AccountCard';
import { userFeaturesAtom } from 'shared/recoil/atoms/userFeatures';

import type { Accounts } from 'shared/components/ConnectAssets/components/AccountCards/consts';

const AccountCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(102px, 1fr));
  grid-template-rows: 116px;
  grid-gap: 16px;
  width: 100%;
  max-width: 100%;
  margin: 32px 0 24px 0;

  & :nth-child(-n + 3) {
    grid-column: span 2;
  }

  & .big {
    grid-column: span 2;
  }

  @media (max-width: 725px) {
    & :nth-child(3) {
      grid-column: span 1;
    }
  }

  @media (max-width: 790px) {
    & :nth-child(2) {
      grid-column: span 1;
    }
  }
`;

interface Card {
  type: string;
  name: string;
  icon: JSX.Element;
  background: string;
  key: Accounts | string;
  className: string;
}

interface Props {
  list: Card[];
  onAccountCardClick: (accountType: Accounts) => void;
}

export const AccountCards = ({ list, onAccountCardClick }: Props) => {
  const [userFeatures] = useRecoilState(userFeaturesAtom);
  const isFierblocks = userFeatures.find(({ name }) => name === 'fireblocks')?.enabled;

  return (
    <AccountCardsWrapper>
      {list
        .filter(({ key }) => (!isFierblocks ? key !== 'fierblocks' : true))
        .map(({ icon, name, type, background, key, className }) => (
          <AccountCard
            onClick={onAccountCardClick}
            key={key}
            accountKey={key as Accounts}
            icon={icon}
            name={name}
            type={type}
            background={background}
            className={className}
          />
        ))}
    </AccountCardsWrapper>
  );
};

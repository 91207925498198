import Bank from 'images/exchanges/bank.svg';
import Binance from 'images/exchanges/binance.svg';
import BinanceUs from 'images/exchanges/BinanceUS.svg';
import Bitfinex from 'images/exchanges/Bitfinex.svg';
// import BitMEX from 'images/exchanges/BitMEX.svg';
import Bitstamp from 'images/exchanges/Bitstamp.svg';
import Bittrex from 'images/exchanges/Bittrex.svg';
import BtcWallet from 'images/exchanges/Btc.svg';
import Bybit from 'images/exchanges/Bybit.svg';
import Cosmos from 'images/exchanges/cosmos.svg';
import CryptoCom from 'images/exchanges/Crypto.com.svg';
import Deribit from 'images/exchanges/Deribit.svg';
import Evm from 'images/exchanges/evm.svg';
import GateIo from 'images/exchanges/Gate.io.svg';
import Gemini from 'images/exchanges/Gemini.svg';
import Huobi from 'images/exchanges/Huobi.svg';
import Kraken from 'images/exchanges/Kraken.svg';
import Kucoin from 'images/exchanges/Kucoin.svg';
import Loose from 'images/exchanges/Loose.svg';
import Near from 'images/exchanges/near.svg';
import Okx from 'images/exchanges/Okx.svg';
import Osmo from 'images/exchanges/osmo.svg';
import Solana from 'images/exchanges/solanaLogoMark.svg';
import TronWallet from 'images/exchanges/tron-coin-logo.svg';
import Xdc from 'images/exchanges/xdc.svg';
import styled from 'styled-components';

import { ExchangesTypes, FIERBLOCKS_EXCHANGE_MAP } from './Exchanges.interfaces';

export const iconsMap: Record<string, any> = {
  cosmos: Cosmos,
  osmo: Osmo,
  xdc: Xdc,
  near_wallet: Near,
  [ExchangesTypes.Bank]: Bank,
  [ExchangesTypes.Solana]: Solana,
  [ExchangesTypes.Binance]: Binance,
  [ExchangesTypes.BinanceFutures]: Binance,
  [ExchangesTypes.BinanceTR]: Binance,
  [ExchangesTypes.BinanceMargin]: Binance,
  [ExchangesTypes.BinanceFuturesCoin]: Binance,
  [ExchangesTypes.BinanceFunding]: Binance,
  [ExchangesTypes.BinanceSpot]: Binance,
  [ExchangesTypes.BinanceEarn]: Binance,
  [ExchangesTypes.BinanceUS]: BinanceUs,
  [ExchangesTypes.Bitfinex]: Bitfinex,
  [ExchangesTypes.BitfinexExchange]: Bitfinex,
  [ExchangesTypes.BitfinexFunding]: Bitfinex,
  [ExchangesTypes.BitfinexMargin]: Bitfinex,
  // [ExchangesTypes.BitMEX]: BitMEX,
  [ExchangesTypes.Bitstamp]: Bitstamp,
  [ExchangesTypes.Bittrex]: Bittrex,
  [ExchangesTypes.BtcWallet]: BtcWallet,
  [ExchangesTypes.Bybit]: Bybit,
  [ExchangesTypes.BybitSpot]: Bybit,
  [ExchangesTypes.BybitUnified]: Bybit,
  [ExchangesTypes.BybitFund]: Bybit,
  [ExchangesTypes.BybitOption]: Bybit,
  [ExchangesTypes.BybitContract]: Bybit,
  [ExchangesTypes.BybitInvestment]: Bybit,
  [ExchangesTypes.BybitUsdtPerpetual]: Bybit,
  [ExchangesTypes.CryptoCom]: CryptoCom,
  [ExchangesTypes.Deribit]: Deribit,
  [ExchangesTypes.Wallet]: Evm,
  [ExchangesTypes.TronWallet]: TronWallet,
  [ExchangesTypes.GateIo]: GateIo,
  [ExchangesTypes.Gemini]: Gemini,
  [ExchangesTypes.Huobi]: Huobi,
  [ExchangesTypes.Kraken]: Kraken,
  [ExchangesTypes.LooseWallet]: Loose,
  [ExchangesTypes.OKX]: Okx,
  [ExchangesTypes.OkexFunding]: Okx,
  [ExchangesTypes.OkexFutures]: Okx,
  [ExchangesTypes.OkexTrading]: Okx,
  [ExchangesTypes.KuCoin]: Kucoin,
  [ExchangesTypes.KucoinMain]: Kucoin,
  [ExchangesTypes.KucoinTrade]: Kucoin,
  [ExchangesTypes.KucoinIsolated]: Kucoin,
};

export const prettyAccountType = (type: string): string => {
  return (
    {
      xdc: 'XDC',
      near_wallet: 'Near',
      bitfinex_exchange: 'Bitfinex Exchange',
      bitfinex_funding: 'Bitfinex Funding',
      bitfinex_margin: 'Bitfinex Margin',
      binance: 'Binance',
      binance_earn: 'Binance Earn',
      binance_spot: 'Binance Spot',
      binance_funding: 'Binance Funding',
      binance_futures: 'Binance Futures',
      binance_futures_coin: 'Binance Futures Coin',
      binance_margin: 'Binance Margin',
      binance_tr: 'Binance TR',
      binance_us: 'Binance US',
      bitfinex: 'Bitfinex',
      bitmex: 'Bitmex',
      bitstamp: 'Bitstamp',
      bittrex: 'Bittrex',
      bybit: 'Bybit',
      bybit_unified: 'Bybit UTA',
      bybit_spot: 'Bybit Spot',
      bybit_fund: 'Bybit Funding',
      bybit_option: 'Bybit USDC Derivatives',
      bybit_contract: 'Bybit Derivatives',
      bybit_investment: 'Bybit Earn',
      bybit_usdt_perpetual: 'Bybit USDT Perpetual',
      crypto_com: 'Crypto com',
      deribit: 'Deribit',
      ftx_us: 'FTX US',
      gate_io: 'Gate IO',
      gate_io_btc_perpetual: 'Gate IO BTC perpetual',
      gate_io_usdt_perpetual: 'Gate IO USDT perpetual',
      gdax: 'Gdax',
      gemini: 'Gemini',
      huobi: 'Huobi',
      kraken: 'Kraken',
      kucoin: 'Kucoin',
      kucoin_main: 'Kucoin Main',
      kucoin_isolated: 'Kucoin Margin',
      kucoin_trade: 'Kucoin Trade',
      okex: 'OKX',
      okex_funding: ' OKX Main',
      okex_trading: 'OKX Trading',
      okex_futures: 'OKX Futures',
      wallet: 'EVM Wallet',
      solana: 'Solana',
      btc_wallet: 'BTC Wallet',
      loose_wallet: 'Loose Wallet',
      tron_wallet: 'Tron Wallet',
      bank: 'Bank Accounts',
    }[type] ?? type
  );
};

interface Props {
  exchange: string;
  width?: number;
  height?: number;
  withDescription?: boolean;
}

export const ExchangeIcon = ({ exchange, width = 30, height, withDescription = true }: Props) => {
  const Icon = iconsMap[exchange] || iconsMap[FIERBLOCKS_EXCHANGE_MAP[exchange]];

  const StyledIcon = Icon
    ? styled(Icon)`
        display: flex;
        width: ${width}px;
        height: ${height || width}px;
        flex-shrink: 0;

        svg {
          width: ${width}px;
          //height: ${width}px;
        }
      `
    : null;
  return (
    <>
      {Icon ? <StyledIcon /> : null}
      {withDescription ? prettyAccountType(exchange) : null}
    </>
  );
};

import { Input as InputAntd } from 'antd';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import FloatLabel from 'shared/components/Atoms/FloatLabel';
import Eye from 'shared/images/icons/eye.svg';

import type { ReactNode } from 'react';
import type { Control } from 'react-hook-form';

const Wrapper = styled.div<{ buttonMode: boolean }>`
  cursor: ${props => (props.buttonMode ? 'pointer' : 'initial')};
  position: relative;

  & input {
    cursor: ${props => (props.buttonMode ? 'pointer' : 'initial')};
    caret-color: ${props => (props.buttonMode ? 'transparent' : 'initial')};
  }
`;

const StyledInput = styled(InputAntd)<{ focused: boolean; $paddingLeftImage: boolean }>`
  padding: ${props =>
    props.focused || props.value || props.type === 'date' || props.type === 'datetime-local'
      ? '24px 75px 10px 16px'
      : '17px 75px 17px 16px'};
  line-height: 1;
  padding-left: ${props => (props.$paddingLeftImage ? '54px' : '16px')};
`;

const Suffix = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const ShowPasswordButton = styled.div`
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }
`;

const Error = styled.div`
  font-size: 13px;
  color: #eb1764;
  top: -10px;
  position: absolute;
  right: 33px;
  width: max-content;
`;

const LeftImage = styled.div`
  top: 12px;
  position: absolute;
  left: 12px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;

interface Props {
  label: string;
  type?: string;
  // value: string;
  name: string;
  control: Control<{ [key: string]: string }>;
  suffix?: ReactNode;
  rules?: {
    required: boolean;
    message: string;
  };
  leftImage?: ReactNode;
  buttonMode?: boolean;
  value?: string | number;
  min?: number;
}

export const Input = ({
  label,
  name,
  control,
  suffix,
  type = 'text',
  rules,
  leftImage,
  buttonMode = false,
  value,
  min,
}: Props) => {
  const [focus, setFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const isPassword = type === 'password';

  const handleFocus = (value: boolean) => setFocus(value);
  const handleShowPassword = () => {
    setShowPassword(prevState => !prevState);
  };

  return (
    <Controller
      rules={{
        ...rules,
        min: min,
      }}
      control={control}
      render={({ field, formState }) => {
        const error =
          formState.errors?.[field.name]?.message && !field.value ? rules?.message : null;

        return (
          <Wrapper buttonMode={buttonMode}>
            <FloatLabel
              type={type}
              leftImg={!!leftImage}
              onFocus={handleFocus}
              {...field}
              label={label}
            >
              <>
                {leftImage ? <LeftImage>{leftImage}</LeftImage> : null}
                <input
                  name={name}
                  autoComplete="new-password"
                  type="password"
                  style={{
                    display: 'none',
                  }}
                  min={min}
                />
                <StyledInput
                  role={isPassword ? 'password' : 'presentation'}
                  $paddingLeftImage={!!leftImage}
                  status={error ? 'error' : undefined}
                  aria-autocomplete="none"
                  autoComplete={isPassword ? 'new-password' : 'off'}
                  type={showPassword ? 'text' : type}
                  focused={focus}
                  size="large"
                  {...field}
                  {...(buttonMode
                    ? {
                        onChange: () => undefined,
                      }
                    : {})}
                  value={value || field?.value}
                  min={min}
                />
              </>
            </FloatLabel>
            <Suffix>
              {error ? <Error>{error}</Error> : null}
              {suffix ? <div>{suffix}</div> : null}
              {isPassword ? (
                <ShowPasswordButton onClick={handleShowPassword}>
                  <Eye />
                </ShowPasswordButton>
              ) : null}
            </Suffix>
          </Wrapper>
        );
      }}
      name={name}
    />
  );
};

import { Button, Card, Typography } from 'antd';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Input } from 'shared/components/Atoms/Input';
import { Tags } from 'shared/components/Atoms/Tag/Tags';
import { AccountCards } from 'shared/components/ConnectAssets/components/AccountCards/AccountCards';
import { ACCOUNT_CARD_LIST } from 'shared/components/ConnectAssets/components/AccountCards/consts';
import { TAGS } from 'shared/components/ConnectAssets/consts';
import { ConnectWalletInput } from 'shared/components/Wallet/ConnectWalletInput/ConnectWalletInput';
import SearchIcon from 'shared/images/icons/search.svg';

import type { Accounts } from 'shared/components/ConnectAssets/components/AccountCards/consts';

const ConnectAssetsWrapper = styled(Card)`
  width: 100%;
  max-width: 745px;
  margin: 34px auto;

  .ant-card-body {
    padding: 34px 24px;
  }

  @media (max-width: 768px) {
    padding: 16px;
    .ant-card-body {
      padding: 0;
    }

    margin: 0;
    border-radius: 0;
    max-width: inherit;
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
`;

export const ManualPortfolioButton = styled(Button)`
  @media (max-width: 339px) {
    width: 57px;
    overflow: hidden;
  }
`;

interface Props {
  onNextAction: (accountType: Accounts) => void;
}

export const ButtonOwnPortfolio = styled(Button)`
  &.ant-btn-sm {
    color: #213df0;
    padding: 12px 16px 12px 16px;
    border-radius: 24px;
    height: auto;
    font-size: 14px;
  }
`;

const [all, wallet, exchange] = TAGS;
export const AccountList = ({ onNextAction }: Props) => {
  const { watch, control } = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  });

  const navigate = useNavigate();
  const [activeTag, setActiveTag] = useState(() => all);
  const search = watch('search');

  const searchValue = search.trim().toLowerCase();
  const accountList = Object.entries(ACCOUNT_CARD_LIST)
    .map(([key, item]) => ({ key, ...item }))
    .filter(({ name }) => (searchValue ? name.toLowerCase().includes(searchValue) : true))
    .filter(({ type }) => (activeTag !== all ? type === activeTag : true));

  const handleChange = (tag: string) => setActiveTag(tag);
  const handleGoToManual = () => navigate('/accounts/new?account=looseWallet');

  return (
    <ConnectAssetsWrapper bordered={false} size="default">
      <Typography.Title level={3}>Connect your assets</Typography.Title>
      <Input
        control={control}
        label="Search for wallet, exchange"
        name="search"
        suffix={<SearchIcon />}
      />
      <TagsWrapper>
        <Tags tags={TAGS} activeTag={activeTag} onChange={handleChange} />
        <ManualPortfolioButton type="link" onClick={handleGoToManual}>
          Manual Portfolio
        </ManualPortfolioButton>
      </TagsWrapper>
      {activeTag === wallet ? <ConnectWalletInput /> : null}
      {accountList.length ? (
        <AccountCards list={accountList} onAccountCardClick={onNextAction} />
      ) : null}
    </ConnectAssetsWrapper>
  );
};

import { Tag as TagAntd } from 'antd';
import styled from 'styled-components';

import type { ReactNode } from 'react';

interface Props {
  name: string;
  checked: boolean;
  onChange: (tag: string) => void;
  children: ReactNode;
}

const StyledTag = styled(TagAntd.CheckableTag)`
  user-select: none;
  background: ${props => (props.checked ? '#2D48F1' : '#e8ebfd')};
  color: ${props => (props.checked ? '#E8EBFD' : '#213df0')};
`;
export const Tag = ({ name, checked, onChange, children }: Props) => {
  const handleOnchange = () => onChange(name);

  return (
    <StyledTag onChange={handleOnchange} checked={checked}>
      {children}
    </StyledTag>
  );
};

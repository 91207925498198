import { theme } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import styled from 'styled-components';

import type { FunctionComponent, ReactElement } from 'react';

const LabelWrapper = styled.div<{ color: string }>`
  position: relative;
  //margin-bottom: 12px;
  color: ${props => props.color};
`;

const Label = styled.label`
  font-size: 17px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 16px;
  transition: 0.2s ease all;
  z-index: 1;

  &.label-img {
    left: 55px;
  }

  &.label-float {
    top: 6px;
    font-size: 13px;
  }

  @media (max-width: 374px) {
    font-size: 13px;
    top: 18px;
  }

  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 56px);
  white-space: nowrap;
  text-align: left;
`;

interface Props {
  label: string;
  value: string;
  children: ReactElement;
  onFocus?: (value: boolean) => void;
  styles?: { [key: string]: string };
  leftImg?: boolean;
  type: string;
}

const { useToken } = theme;

const FloatLabel: FunctionComponent<Props> = ({
  onFocus,
  children,
  label,
  value,
  styles = {},
  leftImg = false,
  type = 'text',
}) => {
  const token = useToken();

  const [focus, setFocus] = useState(false);
  const isDateInput = type === 'date' || type === 'datetime-local';
  const labelClass = classNames({
    label: true,
    'label-float': focus || (value && value.length !== 0) || isDateInput,
    'label-img': leftImg,
  });

  const handleBlur = () => {
    if (onFocus) {
      onFocus(false);
    }
    setFocus(false);
  };
  const handleFocus = () => {
    if (onFocus) {
      onFocus(true);
    }
    setFocus(true);
  };

  return (
    <LabelWrapper
      style={styles}
      color={token.token.Input?.colorTextPlaceholder}
      onBlur={handleBlur}
      onFocus={handleFocus}
    >
      {children}
      <Label className={labelClass}>{label}</Label>
    </LabelWrapper>
  );
};

export default FloatLabel;

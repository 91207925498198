import ArbitrumIcon from 'images/networks/arbitrum.svg';
import AvaxIcon from 'images/networks/avax.svg';
import BscIcon from 'images/networks/bsc.svg';
import CeloIcon from 'images/networks/celo.svg';
import EthIcon from 'images/networks/eth.svg';
import GnosisIcon from 'images/networks/gnosis.svg';
import HecoIcon from 'images/networks/heco.svg';
import OptimismIcon from 'images/networks/op.svg';
import PolygonIcon from 'images/networks/polygon.svg';

export const networks = [
  { key: 'eth', name: 'Ethereum', icon: EthIcon },
  { key: 'ethereum', name: 'Ethereum', icon: EthIcon },

  { key: 'bsc', name: 'Binance Smart Chain', icon: BscIcon },
  { key: 'binance-smart-chain', name: 'Binance Smart Chain', icon: BscIcon },

  { key: 'matic', name: 'Polygon', icon: PolygonIcon },
  { key: 'op', name: 'Optimism', icon: OptimismIcon },
  { key: 'celo', name: 'CELO', icon: CeloIcon },
  { key: 'heco', name: 'Huobi ECO Chain', icon: HecoIcon },
  { key: 'avax', name: 'Avalanche', icon: AvaxIcon },
  { key: 'arb', name: 'Arbitrum', icon: ArbitrumIcon },
  { key: 'xdai', name: 'Gnosis Chain', icon: GnosisIcon },
];

export const networksMap = (() =>
  networks.reduce((accum: Record<string, any>, { key, ...rest }) => {
    accum[key] = { ...rest };

    return accum;
  }, {}))();
